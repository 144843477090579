import { EDIT_CONFIG } from "store/constants/creditConfigConstant";

const initialState = {
  config: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    default:
      return state;
  }
};

